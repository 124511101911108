const formatter = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP',
  minimumFractionDigits: 2,
});

export default function formatCurrency(value) {
  if (typeof value !== 'number') {
    return value;
  }

  return formatter.format(value);
}
