const requireQuery = 'enablePriceList';

const runApp = window
  && window.location
  && window.location.search
  && window.location.search.indexOf(requireQuery) > -1;

export default {
  runApp,
};
