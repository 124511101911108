<template>
  <form @submit.prevent="handleSubmit">
    <div class="pt-search">
      <input
        type="text"
        placeholder="Watch make or model"
        v-model="text"
        @focus="focus">
      <SearchFormResults
        v-if="listVisible"
        :results="matches"
        @select="select($event)" />
    </div>
    <button
        type="submit"
        class="pt-submit"
        :disabled="!selected">Get quote</button>
  </form>
</template>

<script>

import SearchFormResults from './SearchFormResults.vue';

import analytics from '../services/analytics';

function lowerCase(value) {
  return (value && value.toLocaleLowerCase)
    ? value.toLocaleLowerCase()
    : value;
}

function createOption(option, getText) {
  const valueOrValues = getText(option);

  const values = Array.isArray(valueOrValues) ? valueOrValues : [valueOrValues];

  return {
    text: values[0],
    lowerCaseValues: values.map((x) => lowerCase(x)),
    source: option,
  };
}

function isStringMatch(source, prefix) {
  return source && source.indexOf && source.indexOf(prefix) === 0;
}

function isMatch(values, text) {
  return text && values.some((x) => isStringMatch(x, text));
}

export default {
  data() {
    return {
      text: '',
      textOptions: this.options.map((x) => createOption(x, this.getText)),
      selected: null,
    };
  },

  computed: {
    matches() {
      const lowerCaseText = lowerCase(this.text);

      return (this.textOptions || [])
        .filter((x) => isMatch(x.lowerCaseValues, lowerCaseText))
        .slice(0, 8);
    },

    listVisible() {
      return this.matches.length && !this.selected;
    },
  },

  methods: {
    select(item) {
      this.text = item.text;
      this.selected = item;
    },

    focus() {
      this.text = '';
      this.selected = null;
      analytics.writeEvent('focus');
    },

    handleSubmit() {
      if (this.selected) {
        this.$emit('select', this.selected.source);
        analytics.writeEvent('select', this.text);
      }
    },
  },

  components: { SearchFormResults },

  props: {
    options: { type: Array, required: true },
    getText: { type: Function, required: true },
  },
};
</script>

<style scoped>
  form {
    width: 80%;
    min-width: 21em;
    max-width: 32em;;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
  }

  .pt-search > input, .pt-submit {
    font-family: futura-pt, sans-serif;
    font-size: 15px;
    padding: 17px 30px;
    border: 2px solid #fff;
    -webkit-appearance: none;
    outline: 0;
    box-sizing: border-box;
  }

  .pt-search > input {
    border-color: #bea055;
  }

  .pt-search {
    flex-grow: 2;
    display: inline-block;
  }

  .pt-search > input {
    width: 100%;
    min-width: 13em;
  }

  .pt-matches {
    position: relative;
    z-index: 100;
    font-size: 15px;
    width: auto;
  }

  .pt-submit {
    min-width: 11em;
    color: #fff;
    background-color: #1a546b;
    cursor: pointer;
    text-transform: uppercase;
    margin-left: 1em;
    font-weight: bold;
    letter-spacing: 1px;
  }

  .pt-submit:disabled {
    cursor: not-allowed;
    border-color: #fff !important;
    color: #fff !important;
  }

  .pt-submit:hover {
    border-color: #bea055;
    color: #bea055;
  }
</style>
