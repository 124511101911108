<template>
  <ul>
    <li
      v-for="result in results"
      :key="result.text">
      <a
        href="javascript:void(0)"
        @click="select(result)">
          {{result.text}}
        </a>
    </li>
  </ul>
</template>

<script>
export default {
  methods: {
    select(value) {
      this.$emit('select', value);
    },
  },
  props: {
    results: Array,
  },
};
</script>

<style scoped>
  ul {
    position: absolute;
    list-style-type: none;
    background-color: rgba(255,255,255,0.97);
    border: 1px solid #ededed;
    border-top: 0;
    margin: 0 auto;
    padding: 0;
    z-index: 100;
  }

  li {
    text-align: left;
  }

  li > a {
    display: block;
    padding: 17px 30px;
  }

  li > a:hover {
    background-color: #ededed;
  }
</style>
