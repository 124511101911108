<template>
  <div>
    <SearchForm
      :options="priceList"
      :getText="getText"
      @select="handleSelect"></SearchForm>
    <transition name="fade">
      <div class="pt-overlay" v-if="selected" @click.self="handleClose">
        <PriceList
          :selected="selected"
          :products="products"
          @close="handleClose"></PriceList>
      </div>
    </transition>
  </div>
</template>

<script>

import SearchForm from './SearchForm.vue';
import PriceList from './PriceList.vue';
import { disableBodyScroll, enableBodyScroll } from '../services/scrolling';

export default {
  name: 'PriceLookup',

  components: {
    SearchForm,
    PriceList,
  },

  props: {
    priceList: { type: Array, required: true },
    products: { type: Array, required: true },
  },

  data() {
    return {
      selected: null,

      getText: (x) => [x.make.concat(' ', x.model), x.model],
    };
  },

  methods: {
    handleSelect(data) {
      this.selected = data;
    },

    handleClose() {
      this.selected = null;
    },
  },

  watch: {
    selected(value) {
      const action = value ? disableBodyScroll : enableBodyScroll;
      action();
    },
  },
};

</script>

<style scoped>
  .pt-overlay {
    position: fixed;
    overflow-y: scroll;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,0.5);
    z-index: 100;
  }
</style>
