function getBodyTopPosition() {
  const scrollY = document.body.style.top;
  return parseInt(scrollY || '0', 10) * -1;
}

function enableBodyScroll() {
  const scrollPositionBeforeResize = getBodyTopPosition();

  const bodyStyle = document.body.style;
  bodyStyle.position = 'static';
  bodyStyle.top = '';

  window.scrollTo(0, scrollPositionBeforeResize);
}

function disableBodyScroll() {
  const topPositionBeforeResize = `-${window.scrollY}px`;

  const bodyStyle = document.body.style;
  bodyStyle.position = 'fixed';
  bodyStyle.width = '100%';
  bodyStyle.top = topPositionBeforeResize;
}

export {
  enableBodyScroll,
  disableBodyScroll,
};
