<template>
  <Product :product="bespokeProduct" :href="href"></Product>
</template>

<script>
import Product from './Product.vue';

export default {
  components: {
    Product,
  },

  data() {
    return {
      bespokeProduct: {
        name: 'Bespoke Restoration',
        features: ['Contact us to discuss your requirements'],
        price: 'POA',
      },
    };
  },

  props: {
    href: String,
  },
};
</script>
