<template>
  <div id="pt-pricelist-app">
    <div class="pt-container">
      <div>
        <div class="pt-pricelist-content">
          <h4>High Quality Service</h4>
          <h2>Get an instant quote for servicing your watch</h2>
        </div>
        <PriceLookup
          :products="products"
          :priceList="priceList"
          class="pt-price-lookup"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PriceLookup from './components/PriceLookup.vue';
import productsJson from './assets/products.json';
import priceListJson from './assets/priceList.json';
import quartzPriceListJson from './assets/quartzPriceList.json';

export default {
  name: 'App',

  components: {
    PriceLookup,
  },

  data() {
    if (!(productsJson && productsJson.products)) {
      throw new Error('Failed to load products');
    }

    if (!(priceListJson && priceListJson.priceList)) {
      throw new Error('Failed to load price list');
    }

    if (!(quartzPriceListJson && quartzPriceListJson.priceList)) {
      throw new Error('Failed to load quartz price list');
    }

    const priceList = priceListJson.priceList
      .concat(quartzPriceListJson.priceList);

    return {
      products: productsJson.products,
      priceList,
    };
  },
};
</script>

<style>
  #pt-pricelist-app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .pt-container {
    background-color: #1a546b;
    padding: 3em 0 4em 0;
  }

  .pt-container > div {
    max-width: 960px;
    margin: 0 auto;
  }

  .pt-pricelist-content {
    padding: 0 1em;
    margin-bottom: 1.5em;
    text-align: center;
  }

  .pt-pricelist-content > h2 {
    color: #fff;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 3px;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.25s ease-out;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>
