import Vue from 'vue';
import App from './App.vue';
import featureToggle from './services/featureToggle';
import currencyFormatter from './services/currencyFormatter';

Vue.config.productionTip = false;

const skipFeatureToggle = true;

if (skipFeatureToggle || featureToggle.runApp) {
  Vue.filter('currency', currencyFormatter);

  new Vue({
    render: (h) => h(App),
  }).$mount('#pt-pricelist-app');
}
