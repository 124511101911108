const gaAlias = '__gaTracker';

const timeout = 800;

const dontLog = (_action, _context, callback) => {
  if (typeof callback === 'function') {
    callback();
  }
};

const gaSend = (ga) => (action, context, callback) => {
  let hitCallback;

  if (typeof callback === 'function') {
    const fallback = setTimeout(() => {
      callback();
    }, timeout);

    hitCallback = () => {
      clearTimeout(fallback);
      callback();
    };
  }

  const eventArgs = {
    eventCategory: 'priceList',
    eventAction: action,
    eventLabel: context,
    hitCallback,
  };

  ga('send', 'event', eventArgs);
};

const getGa = (window) => {
  const ga = window && window[gaAlias];

  return ga && ga.loaded && gaSend(ga);
};

export default {
  writeEvent(action, context, callback) {
    const logEvent = getGa(window) || dontLog;

    logEvent(action, context, callback);
  },
};
