<template>
  <div class="pt-product">
    <div class="pt-main">
      <p
        v-if="product.warrantyMonths"
        class="pt-warranty">
          Guaranteed for {{product.warrantyMonths}} months
      </p>
      <h2>{{product.name}} &mdash; {{product.price | currency}}</h2>
      <ul class="pt-features">
        <li v-for="feature in product.features" :key="feature">
          &rarr; {{feature}}
        </li>
      </ul>
    </div>
    <div class="pt-cta">
      <a :href="href" @click.stop="select">Contact Us</a>
    </div>
  </div>
</template>

<script>

import analytics from '../services/analytics';

export default {
  methods: {
    select() {
      const navigate = () => {
        window.location = this.href;
      };

      try {
        analytics.writeEvent('navigate', this.product.name, () => { navigate(); });
      } catch {
        navigate();
      }
    },
  },

  props: {
    href: String,
    product: Object,
  },
};
</script>

<style scoped>
  .pt-product {
    color: #90afbb;
    text-decoration: none;
  }

  .pt-main,
  .pt-cta {
    padding: 2.5rem;
  }

  .pt-main {
    padding-bottom: 8rem;
  }

  .pt-cta {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0;
  }

  .pt-main h2 {
    margin-top: 7px;
    color: #fff;
    font-family: futura-pt, sans-serif;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 3px;
  }

  .pt-features {
    display: block;
    list-style-type: none;
    padding: 0;
    margin-top: 1.8em;
  }

  .pt-features > li {
    margin-bottom: 0.6em;
    padding-bottom: 0.6em;
  }

  .pt-features > li:last-child {
    border-bottom: 0;
  }

  .pt-cta a {
    display: inline-block;
    border: 2px solid #fff;
    padding: 1rem 2rem;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-decoration: none;
    transition: all .5s ease-in-out;
  }

  .pt-cta a:hover {
    border-color: #bea055;
    color: #bea055;
    transition: all .5s ease-in-out;
  }

  .pt-warranty {
    margin: 0;
    font-family: linotype-didot, serif;
    font-style: italic;
    font-weight: 400;
    text-transform: none;
    color: #bea055;
    font-size: 1.4rem;
  }
</style>
