<template>
  <div class="pt-pricelist">
    <button
      class="pt-close"
      @click="close"
      title="Close">&times;</button>
    <h1>Restoring &amp; Servicing your
      <span class="pt-watch">{{selected.make}} {{selected.model}}</span>
    </h1>
    <ul class="pt-products">
      <li v-for="product in applicableProducts" :key="product.id">
        <Product :product="product" :href="'/contact'">
        </Product>
      </li>
      <li v-if="applicableProducts.length === 0">
        <BespokeProduct :href="'/contact'"></BespokeProduct>
      </li>
    </ul>
    <p class="pt-notes">
      <ul>
        <li>Prices quoted are subject to initial inspection and parts availability.</li>
        <li>Prices may vary for vintage timepieces.</li>
        <li>
          Please <a href="/contact">contact us</a>
          for any other specialist watch restoration services not listed.</li>
      </ul>
    </p>
  </div>
</template>

<script>
import Product from './Product.vue';
import BespokeProduct from './BespokeProduct.vue';

export default {
  components: {
    Product,
    BespokeProduct,
  },

  data() {
    return {};
  },

  computed: {
    applicableProducts() {
      return this.products
        .map((x) => ({
          ...x,
          price: this.selected[x.id],
          warrantyMonths: x.warranty && this.selected.warrantyMonths,
        }))
        .filter((x) => x.price && x.price !== 'N/A');
    },
  },

  methods: {
    close() {
      this.$emit('close');
    },
  },

  props: {
    selected: Object,
    products: Array,
  },
};
</script>

<style scoped>
  .pt-pricelist {
    position: relative;
    box-sizing: border-box;
    background-color: #fff;
    width: 90%;
    margin: 10em auto;
    padding: 4em 1em;
  }

  .pt-pricelist > h1 {
    margin: 1.5em 0;
    color: #000;
    font-family: futura-pt, sans-serif;
    font-style: normal;
    font-size: 27px;
    font-weight: 400;
    letter-spacing: 3px;
    line-height: 35px;
    text-transform: uppercase;
    text-align: center;
  }

  .pt-close {
    position: absolute;
    top: 20px;
    right: 30px;
    border: 0;
    font-size: 50px;
    cursor: pointer;
    background-color: #fff;
  }

  .pt-watch {
    white-space: nowrap;
  }

  @media screen and (max-width: 480px) {
    .pt-watch {
      white-space: normal;
    }
  }

  .pt-products {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    list-style-type: none;
    padding: 0;
  }

  .pt-products > li {
    min-width: 15em;
    margin: 15px;
    position: relative;
    flex: auto;
    background-color: #1a546b;
  }

  .pt-notes {
    background-color: #ddd;
    color: #797979;
    margin: 15px;
    padding: 45px 30px;
  }

  .pt-notes a {
    color: #797979;
    text-decoration: underline;
  }

</style>
